const modal = document.getElementById('lang-modal');

if (modal) {
  const dismissed = localStorage.getItem('lang-modal-dismissed');
  const langButtons = document.querySelectorAll('.LangModal-switcherLang');
  const closeButton = document.querySelector('.LangModal-closeBtn');

  if (!dismissed || dismissed === '0' || dismissed === 0) {
    modal.classList.remove('isHidden');
  }

  closeButton.addEventListener('click', (e) => {
    localStorage.setItem('lang-modal-dismissed', 1);
    modal.classList.add('isHidden');
  });

  langButtons.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault();

      localStorage.setItem('lang-modal-dismissed', 1);

      window.location.href = btn.getAttribute('href');
    });
  });
}
