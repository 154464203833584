// Libs
import Alpine from 'alpinejs';

// Components
import './mobileDetect';
import '../views/components/Navbar/Navbar';
import '../views/components/NavbarMobile/NavbarMobile';
import '../views/components/MainHeader/MainHeader';
import '../views/components/LangModal/LangModal';
import './gallery'; // fslightbox
// import './fadeIn'; // Simple fade in
// import './parallax'; // Simple prallax with Rellax

window.Alpine = Alpine;

Alpine.start();
