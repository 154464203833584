const navbarMobile = document.getElementById('navbar-mobile');

if (navbarMobile) {
  const navToggle = document.getElementById('navbar-toggle');
  const menuItems = navbarMobile.getElementsByClassName(
    'NavbarMobile-item--parent'
  );
  const subNavs = navbarMobile.getElementsByClassName('NavbarMobile-subMenu');
  const pageOverlay = navbarMobile.querySelector('.NavbarMobile-pageOverlay');
  const subToggles = navbarMobile.querySelectorAll('.NavbarMobile-subToggle');

  const onNavToggleClick = (e) => {
    e.preventDefault();

    navbarMobile.classList.toggle('isOpen');
    navToggle.classList.toggle('isOpen');

    if (navToggle.classList.contains('isOpen')) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  const closeSubNavs = (e) => {
    if (e) e.preventDefault();

    pageOverlay.classList.remove('isActive');
    for (let i = 0; i < subNavs.length; i++) {
      const nav = subNavs[i];
      nav.classList.remove('isActive');
    }
  };

  const onNavItemClick = (e) => {
    closeSubNavs();
    const parentId = e.target.getAttribute('data-parent');
    const sub = navbarMobile.querySelector(`[data-sub="${parentId}"]`);
    if (sub) {
      e.preventDefault();
      sub.classList.add('isActive');
      pageOverlay.classList.add('isActive');
    }
  };

  const onScroll = () => {
    if (window.pageYOffset > window.innerHeight / 3) {
      navToggle.classList.add('isVisible');
    } else {
      navToggle.classList.remove('isVisible');
    }
  };

  window.addEventListener('scroll', onScroll);

  navToggle.addEventListener('click', onNavToggleClick);

  for (let i = 0; i < menuItems.length; i += 1) {
    const item = menuItems[i];
    item.addEventListener('click', onNavItemClick);
  }

  for (let i = 0; i < subToggles.length; i += 1) {
    const item = subToggles[i];
    item.addEventListener('click', closeSubNavs);
  }

  onScroll();
}
